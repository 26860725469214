









































































































































































































import Vue from "vue";
import { userDelete, userQuery } from "@/api/user";
import editForm from "./editForm.vue";
import passwoForm from "./passwoForm.vue";
const myWindow = require("@/../public/config");
import { getPubkey } from "@/api/modules/pubkey";
export default Vue.extend({
  components: { editForm, passwoForm },
  data() {
    return {
      staList: [
        { name: "员工总数", value: 0 },
        { name: "本月新入职", value: 0 },
        { name: "本月离职", value: 0 },
        { name: "未定薪", value: 0 },
        { name: "未分组", value: 0 },
      ],
      searchObj: {
        name: "",
        username: "",
      },
      pageObj: {
        pageSize: 10,
        pageNum: 1,
      },
      pageSizesList: [10, 15, 20, 50, 100],
      tableTotal: 0,
      tableData: [],
      editFormVisible: false,
      isEditFlag: false,
      isEditType: "see",
      baseEditInfo: {},

      baseUrl:
        process.env.NODE_ENV == "development"
          ? myWindow.configObj.VUE_APP_BASE_API
          : "",
      roleTypeList: [],
    };
  },
  async mounted() {
    await getPubkey({}).then((res: any) => {
      this.$authModule.setPubkey({ pubkey: res.content });
    });
    this.roleDicts().then((res: any) => {
      this.roleTypeList = res.content || [];
    });
    await this.getList();
  },
  methods: {
    //common
    resetFn() {
      this.searchObj = this.$options.data().searchObj;
      this.timeValue = "";
      this.searchFn();
    },
    searchFn() {
      this.pageObj = this.$options.data().pageObj;
      this.getList();
    },
    pageNumChange(val: any) {
      this.pageObj.pageNum = val;
      this.getList();
    },
    pageSizeChange(val: any) {
      this.pageObj.pageNum = 1;
      this.pageObj.pageSize = val;
      this.getList();
    },
    getList() {
      this.getListData();
      this.getStaData();
    },
    listEditFn(row: any, type: string) {
      this.editFormVisible = true;
      this.isEditFlag = type == "see" ? false : true;
      this.isEditType = type;
      this.baseEditInfo = row;
    },
    listDeleteFn(row: any) {
      this.$confirm("是否删除本条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        userDelete(row.id).then(() => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.searchFn();
        });
      });
    },
    addFn() {
      this.editFormVisible = true;
      this.baseEditInfo = this.$options.data.baseEditInfo;
      this.isEditFlag = true;
      this.isEditType = "add";
    },
    deleteFn() {
      console.log("删除");
    },
    closeFn(flag = false) {
      this.editFormVisible = false;
      if (flag) {
        this.getList();
      }
    },
    //业务

    getListData() {
      let obj = Object.assign({}, this.searchObj, this.pageObj);
      userQuery(obj).then((res) => {
        this.tableData = res.content.records || [];
        this.tableTotal = res.content.total || 0;
      });
    },
    getStaData() {
      console.log("获取统计数据");
    },
  },
});
