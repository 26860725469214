





















































































































































































import Vue from "vue";
import uploadFilesForm from "@/components/common/uploadFilesForm.vue";
import { userAdd, userUpdate, userDetails } from "@/api/user";
import { Des_encrypt } from "@/utils/pubkey";
import { groupList } from "@/api/user/group";
import {
  validatorEmail,
  validatorPhone,
  userName,
  userPassward,
} from "@/utils/validate";
import { genderList } from "@/api/system/dict";
export default Vue.extend({
  components: { uploadFilesForm },
  props: ["isEditFlag", "baseEditInfo", "isEditType"],
  data() {
    return {
      editFormInfo: {
        username: null, //用户名
        name: null, //真实名
        phone: null, //电话
        email: null, //邮箱
        gender: null, //性别
        avatar: null, //头像
        password: null, //密码
        wechatOpenid: null,
        roles: [], //用户角色{role:null}
        permissions: [], //用户权限
        groupId: null, //分组Id
      },
      editFormInfoRules: {
        username: [
          { required: true, message: "请输入用户名" },
          // { validator: userName, trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入用户密码" },
          { validator: userPassward, trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入电话" },
          {
            validator: validatorPhone,
            trigger: ["blur"],
          },
        ],
        roles: [{ required: true, message: "请选择用户角色" }],
        permissions: [{ required: true, message: "请选择用户权限" }],
        email: [{ validator: validatorEmail, trigger: "blur" }],
      },
      //字典
      genderTypeList: [],
      roleTypeList: [],
      permissionTypeList: [],
      groupTypeList: [],
    };
  },
  computed: {
    showAvtar() {
      if (this.editFormInfo.avatar) {
        return [
          {
            filePath: this.editFormInfo.avatar,
            fileType: 1,
          },
        ];
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.genderTypeList = genderList;
    if (this.baseEditInfo && this.baseEditInfo.id) {
      userDetails(this.baseEditInfo.id).then((res: any) => {
        this.editFormInfo = this.$deepCopy(res.content);
        if (
          this.editFormInfo.roles &&
          this.editFormInfo.roles instanceof Array
        ) {
          this.editFormInfo.roles = this.editFormInfo.roles.map((c: any) => {
            return c.role;
          });
          this.editFormInfo.permissions = this.editFormInfo.permissions.map(
            (c: any) => {
              return c.permission;
            }
          );
        }
      });
    }
    this.roleDicts().then((res: any) => {
      this.roleTypeList = res.content || [];
    });
    this.permissionDicts().then((res: any) => {
      this.permissionTypeList = res.content || [];
    });
    groupList({}).then((res: any) => {
      this.groupTypeList = res.content.content.records || [];
    });
  },
  methods: {
    saveFn: async function () {
      let flag = true;
      (this.$refs.editFormInfoForm as any).validate((valid: boolean) => {
        if (!valid) {
          flag = false;
        }
      });
      if (!flag) {
        return;
      }

      let obj = this.$deepCopy(this.editFormInfo);

      if (obj.id) {
        delete obj.password;
      }

      if (obj.password) {
        obj.password = await Des_encrypt(obj.password);
      }

      if (obj.roles) {
        obj.roles = obj.roles.map((c) => {
          return {
            role: c,
          };
        });
      }
      if (obj.permissions) {
        obj.permissions = obj.permissions.map((c: any) => {
          return {
            permission: c,
          };
        });
      }
      if (obj.id) {
        userUpdate(obj.id, obj).then(() => {
          this.$message.success("保存成功");
          this.closeFn(true);
        });
      } else {
        userAdd(obj).then(() => {
          this.$message.success("保存成功");
          this.closeFn(true);
        });
      }
    },
    closeFn(flag = false) {
      this.$emit("closeFn", flag);
    },

    //处置上传文件回调
    fileUploadSuccess(val: any) {
      this.editFormInfo.avatar = val.filePath;
      (this.$refs.editFormInfoForm as any).validateField("avatar");
    },
    fileDeleteFn() {
      this.editFormInfo.avatar = null;
    },
  },
});
