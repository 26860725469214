<template>
  <div class="uploadFilesFormBox">
    <div class="uploadFileBtn" v-if="isEditFlag" @click="openChooseFn">
      <i class="el-icon-plus"></i>
      <label class="file-select">
        <input
          type="file"
          ref="fileUploadForm"
          multiple
          @change="handleFileChange"
        />
      </label>
    </div>

    <div class="fileShowItem" v-for="(file, idx) in fileShowList" :key="idx">
      <el-image
        :ref="'fileDouc' + idx"
        v-if="file.fileType == imgType"
        :src="$getHunImg(file.filePath, baseUrl, '')"
        :onerror="errImg"
        :preview-src-list="[$getHunImg(file.filePath, baseUrl, '')]"
      ></el-image>
      <video
        v-else-if="file.fileType == videoType"
        :src="$getHunImg(file.filePath, baseUrl, '')"
        muted
        autoplay
        :controls="false"
        :ref="'fileDouc' + idx"
        @click="showWaPlayerFn($getHunImg(file.filePath, baseUrl, ''), 'video')"
      ></video>
      <div
        class="waMiniPlayerShowBox"
        :ref="'fileDouc' + idx"
        v-else-if="file.fileType == audioType"
        @click="showWaPlayerFn($getHunImg(file.filePath, baseUrl, ''), 'audio')"
      >
        <!-- <wa-mini-player
          :videoUrl="$getHunImg(file.filePath, baseUrl, '')"
          :dblclick-visibility="false"
        ></wa-mini-player>-->
      </div>
      <div
        v-else
        :ref="'fileDouc' + idx"
        :class="[
          'wordIcon',
          { pdfIcon: getFileType(file.filePath).type == 'pdf' },
        ]"
        @click="showDocumentFn(file.filePath)"
      ></div>
      <span class="fileShowItem_tool">
        <span
          class="fileShowItem_tool_preview"
          @click.stop="previewFn('fileDouc' + idx, file)"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
        <span
          class="fileShowItem_tool_download"
          @click="downloadFn(file)"
          v-if="false"
        >
          <i class="el-icon-download"></i>
        </span>
        <span
          class="fileShowItem_tool_delete"
          @click="deleteFn(file, idx)"
          v-if="isEditFlag"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
    </div>
    <div class="shadeBox" v-if="uploadImageFlag">
      <circleChart
        :rate="uploadProgress"
        bg-ring-color="#13387e"
        ring-color="#00bbff"
        :stroke-width="40"
        :part="1"
        :part-gap="0"
      >
        <span class="pergress_font">
          <span>{{ `${(uploadProgress * 100).toFixed(2)}%` }}</span>
        </span>
      </circleChart>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :modal-append-to-body="true"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeEmbedFn"
      custom-class="file_play_dialog"
      @dblclick.stop
    >
      <div v-if="fileType == 'video'" class="diaPalyerBox">
        <video :src="embedUrl" autoplay controls></video>
      </div>
      <div v-else-if="fileType == 'audio'" class="diaPalyerBox">
        <!-- <wa-mini-player
          :videoUrl="embedUrl"
          :dblclick-visibility="false"
        ></wa-mini-player>-->
      </div>
      <embed v-else :src="embedUrl" autostart="true" type />
    </el-dialog>
  </div>
</template>
<script>
// import waMiniPlayer from "./waMiniPlayer.vue";
import circleChart from "./circleChart.vue";
import axios from "axios";
const myWindow = require("@/../public/config");
import authModule from "@/store/modules/auth";
export default {
  components: {
    // "wa-mini-player": waMiniPlayer,
    circleChart,
  },
  props: {
    fileList: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    isEditFlag: {
      type: Boolean,
      default: false,
    },
    acceptType: {
      type: Array,
      default: () => {
        return ["img", "video", "audio", "document"];
      },
    },
    imgType: {
      type: Number,
      default: 1,
    },
    audioType: {
      type: Number,
      default: 2,
    },
    videoType: {
      type: Number,
      default: 3,
    },
    txtType: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    fileShowList() {
      return this.fileList;
    },
  },
  watch: {
    fileList(val) {
      //debugger
    },
  },
  data() {
    return {
      tableAllList: [],
      imgExit: ["jpeg", "jpg", "png"],
      videoExit: ["mov", "mp4"],
      audioExit: ["mp3"],
      documentExts: [
        "docx",
        "doc",
        "pptx",
        "ppt",
        "pdf",
        "xml",
        "html",
        "json",
        "txt",
        "xlsx",
        "xls",
      ],
      fileType: "",
      baseUrl:
        process.env.NODE_ENV == "development"
          ? myWindow.configObj.VUE_APP_BASE_API
          : "",
      errImg: 'this.src="' + require("@/assets/common/errimg.png") + '"',
      dialogVisible: false,
      embedUrl: "",

      uploadFileSizeMax: 1000, //最大文件总和
      sizeUnit: 1024 * 1024, //MB
      uploadImageFlag: false,
      uploadProgress: 0,
      uploadUrl:
        (process.env.NODE_ENV == "development"
          ? myWindow.configObj.VUE_APP_BASE_API
          : "") + "/api/file/",
    };
  },
  provide() {
    return {};
  },
  methods: {
    openChooseFn() {
      this.$refs.fileUploadForm.click();
    },
    previewFn(val, file) {
      if (file.fileType == this.imgType) {
        this.$refs[val][0].showViewer = true;
      } else {
        this.$refs[val][0].click();
      }
    },
    downloadFn(file) {
      const elink = document.createElement("a");
      elink.download = file.fileName || "文件";
      elink.style.display = "none";
      elink.href = URL.createObjectURL(file.filePath);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href);
      document.body.removeChild(elink);
    },
    deleteFn(file, idx) {
      this.$confirm("是否删除此附件?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$emit("deleteFile", {
          file: file,
          idx: idx,
        });
      });
    },
    getFileType(val) {
      if (val) {
        let exit_ = val.substring(val.lastIndexOf(".") + 1).toLowerCase();
        if (this.audioExit.findIndex((c) => c == exit_) != -1) {
          return { flag: true, type: exit_ };
        } else {
          return { flag: false, type: exit_ };
        }
      } else {
        return { flag: false, type: "" };
      }
    },
    showWaPlayerFn(val, type) {
      this.dialogVisible = true;
      this.fileType = type;
      this.embedUrl = val;
    },
    showDocumentFn(val) {
      this.fileType = "document";
      this.dialogVisible = true;
    },
    closeEmbedFn() {
      this.embedUrl = "unkonw";
    },
    //上传按钮事件
    handleFileChange(file, fileLists) {
      //本地程序需解析文件获取本地文件绝对路径
      this.beforeUpload(this.$refs.fileUploadForm.files);
      this.$refs.fileUploadForm.value = "";
    },
    beforeUpload(files) {
      let sizesTotal = 0;
      for (let i = 0; i < files.length; i++) {
        sizesTotal += files[i].size;
      }

      if (sizesTotal > this.uploadFileSizeMax * this.sizeUnit) {
        this.$message.warning("已超出文件大小限制(1000MB)!");
        return;
      }
      let exits = [];

      if (this.acceptType.findIndex((c) => c == "img") != -1) {
        exits = exits.concat(this.imgExit);
      }

      if (this.acceptType.findIndex((c) => c == "video") != -1) {
        exits = exits.concat(this.videoExit);
      }

      if (this.acceptType.findIndex((c) => c == "audio") != -1) {
        exits = exits.concat(this.audioExit);
      }

      if (this.acceptType.findIndex((c) => c == "document") != -1) {
        exits = exits.concat(this.documentExts);
      }

      for (let i = 0; i < files.length; i++) {
        if (
          exits.findIndex(
            (c) =>
              c ==
              files[i].name
                .substring(files[i].name.lastIndexOf(".") + 1)
                .toLowerCase()
          ) != -1
        ) {
          this.tableAllList.push({
            name: files[i].name,
            src: window.URL.createObjectURL(files[i]),
            fileCreateTime: this.showTime(files[i].lastModifiedDate, true),
            exit: files[i].name
              .substring(files[i].name.lastIndexOf(".") + 1)
              .toLowerCase(),
            file: files[i],
            type:
              files[i].type.indexOf("image") != -1
                ? this.imgType
                : files[i].type.indexOf("video") != -1
                ? this.videoType
                : files[i].type.indexOf("audio") != -1
                ? this.audioType
                : this.txtType,
            uuid: this.tableAllList.length,
            size: files[i].size,
          });
        } else {
          this.$message.warning(
            "暂不支持" +
              files[i].name.substring(files[i].name.lastIndexOf(".") + 1) +
              "格式文件上传"
          );
        }
        if (this.tableAllList.length > 0) {
          this.uploadExcel(this.tableAllList);
        }
      }
    },
    uploadExcel: async function (files) {
      this.uploadImageFlag = true;
      this.uploadProgress = 0;
      const token = await authModule.getToken();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
          token: token,
        },
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = Number(
            parseFloat(progressEvent.loaded / progressEvent.total).toFixed(4)
          );
        },
      };
      let formData = new FormData();
      files.forEach((item) => {
        formData.append("file", item.file);
      });

      axios
        .post(this.uploadUrl, formData, config)
        .then((res) => {
          if (res.data.status.toLowerCase() != "success") {
            this.$message.warning(res.data.message);
            this.uploadImageFlag = false;
          } else {
            this.$message.success("导入成功");
            this.uploadImageFlag = false;

            this.$emit("fileUploadSuccess", {
              fileType: files[0].type,
              filePath: res.data.content.path,
              fileName: files[0].name,
            });
          }
          this.tableAllList = [];
        })
        .catch((res) => {
          this.$message.warning("导入失败");
          this.uploadImageFlag = false;
          this.tableAllList = [];
        });
    },
    showTime(dateStr, flag = false) {
      if (dateStr) {
        const dateStrTo = new Date(dateStr);
        return (
          dateStrTo.getFullYear() +
          "-" +
          (dateStrTo.getMonth() + 1 < 10
            ? "0" + (dateStrTo.getMonth() + 1)
            : dateStrTo.getMonth() + 1) +
          "-" +
          (dateStrTo.getDate() < 10
            ? "0" + dateStrTo.getDate()
            : dateStrTo.getDate()) +
          (flag
            ? " " +
              (dateStrTo.getHours() < 10
                ? "0" + dateStrTo.getHours()
                : dateStrTo.getHours()) +
              ":" +
              (dateStrTo.getMinutes() < 10
                ? "0" + dateStrTo.getMinutes()
                : dateStrTo.getMinutes()) +
              ":" +
              (dateStrTo.getSeconds() < 10
                ? "0" + dateStrTo.getSeconds()
                : dateStrTo.getSeconds())
            : "")
        );
      } else {
        return "";
      }
    },
  },
  // created: async function () {

  // },
};
</script>
<style scoped lang="less">
.uploadFilesFormBox {
  .uploadFileBtn {
    width: 13vh;
    height: 13vh;
    line-height: 13vh;
    background: #eeeeee;
    text-align: center;
    color: #078d77;
    font-size: 1.67vw;
    margin: 0.5vh 0.25vw;
    cursor: pointer;
    float: left;
    &:hover {
      color: #30d6ba;
    }
    .file-select {
      cursor: pointer;
    }
    .file-select > input[type="file"] {
      display: none;
    }
  }
  .fileShowItem {
    width: 13vh;
    height: 13vh;
    float: left;
    cursor: pointer;
    position: relative;
    margin: 0.5vh 0.25vw;
    // &:nth-child(3n + 2),
    // &:nth-child(3n + 3) {
    //   margin-left: 0.5vw;
    // }
    // &:nth-child(n + 4) {
    //   margin-top: 1vh;
    // }
    .waMiniPlayerShowBox,
    .el-image,
    img,
    .wordIcon,
    video {
      height: 100%;
      width: 100%;
      object-fit: fill;
      z-index: 99;
    }

    .wordIcon {
      background: url("~@/assets/common/icon_word.png") no-repeat;
      background-size: 100% 100%;
    }
    .pdfIcon {
      background: url("~@/assets/common/icon_pdf.png") no-repeat;
      background-size: 100% 100%;
    }
    .fileShowItem_tool {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      height: 100%;
      width: 100%;
      font-size: 1vw;
      text-align: center;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
      transition: opacity 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 99;
      span {
        display: none;
        cursor: pointer;
        &:nth-child(n + 2) {
          margin-left: 0.5vw;
        }
      }
      &:hover {
        opacity: 1;
        span {
          display: inline-block;
          &:hover {
            opacity: 0.7;
          }
        }
      }
      &:hover::after {
      }
    }
  }
  .shadeBox {
    width: 13vh;
    height: 13vh;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0.5vh 0.25vw;
    background: #eeeeee;
    .pergress_font {
      font-size: 0.5vw;
    }

    .progressBox {
      width: 33%;
      background: #838383;
      height: 1vh;
      border-radius: 0.5vh;
      .progress {
        background: #008375;
        height: 1vh;
        border-radius: 0.5vh;
      }
    }
    .labelBox {
      margin-left: 1vw;
      display: flex;
      align-items: center;
      font-weight: bold;
      .lbSl {
        font-size: 0.5vw;
        font-weight: 500;
      }
    }
  }
}
.file_play_dialog {
  embed,
  .diaPalyerBox {
    width: 100%;
    height: 60vh;
    position: relative;

    img,
    video {
      height: 100%;
      width: 100%;
      object-fit: fill;
    }
  }
}
</style>
